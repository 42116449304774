if (window.parent === window) {
  // grab url running on
  const hostUrl = document.location.origin;
  let teamID = "61bc6266e0cea10018e48049"

  // programatic brt script injection (snippet from docs)
  const s = document.createElement( 'script' );
  const link = `${hostUrl}/script.js?id=${teamID}`;

  s.setAttribute( 'src', link );
  s.setAttribute( 'id', 'brt-script' );

  // Global site tag (gtag.js) - Google Analytics
  const s2 = document.createElement( 'script' );
  s2.setAttribute( 'src', 'https://www.googletagmanager.com/gtag/js?id=UA-148482653-2' );

  document.head.appendChild(s);
  document.head.appendChild(s2);

  // Hotjar Tracking Code for https://www.bytesroute.com/app
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:1683040,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  addEventListener('DOMContentLoaded', (event) => {
    const noCookiesNoFun = document.getElementById('nocookiesnofun');
    try {
      console.log(localStorage);
    } catch (error) {
      noCookiesNoFun.classList.remove('d-none');
    }
  });
}
